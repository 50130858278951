const musicDetail = {
  "code": 200,
  "relatedVideos": {},
  "playlist": {
    "tracks":[
      {'name': '奇异恩典（锁链断开）.mp3', 'id': '01', 'dt': 311.1444897959184, 'url': '/mp3/01 奇异恩典（锁链断开）.mp3'},
      {'name': '大山为我挪开（D调）', 'id': '02', 'dt': 202.99755102040817, 'url': '/mp3/02 大山为我挪开（D调）.mp3'},
      {'name': '超越万物的爱（D调）.mp3', 'id': '03', 'dt': 376.555, 'url': '/mp3/03 超越万物的爱（D调）.mp3'},
      {'name': '万民同来敬拜.mp3', 'id': '04', 'dt': 237.4530625, 'url': '/mp3/04 万民同来敬拜.mp3'},
      {'name': '空谷的回音.mp3', 'id': '05', 'dt': 387.29675, 'url': '/mp3/05 空谷的回音.mp3'},
      {'name': '如果.mp3', 'id': '06', 'dt': 255.60526077097506, 'url': '/mp3/06 如果.mp3'},
      {'name': '唯有主耶稣的宝血（F调）.mp3', 'id': '07', 'dt': 127.16408163265307, 'url': '/mp3/07 唯有主耶稣的宝血（F调）.mp3'},
      {'name': '不是我，乃是基督在里面活.MP3', 'id': '08', 'dt': 381.152625, 'url': '/mp3/08 不是我，乃是基督在里面活.MP3'},
      {'name': '何等恩典（G调）.mp3', 'id': '09', 'dt': 287.04975, 'url': '/mp3/09 何等恩典（G调）.mp3'},
      {'name': '最知心的朋友.mp3', 'id': '10', 'dt': 249.0250625, 'url': '/mp3/10 最知心的朋友.mp3'},
      {'name': 'WHAT A FRIEND WE HAVE IN JESUS', 'id': '11', 'dt': 165.59020408163266, 'url': '/mp3/11 WHAT A FRIEND WE HAVE IN JESUS.mp3'},
      {'name': '耶稣恩友.mp3', 'id': '12', 'dt': 160.14491666666666, 'url': '/mp3/12 耶稣恩友.mp3'},
      {'name': '这一生最美的祝福.mp3', 'id': '13', 'dt': 76.273, 'url': '/mp3/13 这一生最美的祝福.mp3'},
      {'name': '医治的爱.mp3', 'id': '14', 'dt': 337.243125, 'url': '/mp3/14 医治的爱.mp3'},
      {'name': '恩典的记号.mp3', 'id': '15', 'dt': 354.3508125, 'url': '/mp3/15 恩典的记号.mp3'},
      {'name': '数算主恩歌.mp3', 'id': '16', 'dt': 161.439, 'url': '/mp3/16 数算主恩歌.mp3'},
      {'name': '我是被主重价买回的人.mp3', 'id': '17', 'dt': 218.256, 'url': '/mp3/17 我是被主重价买回的人.mp3'},
      {'name': '依然是你（F调）.mp3', 'id': '18', 'dt': 397.32244897959185, 'url': '/mp3/18 依然是你（F调）.mp3'},
      {'name': '主！我要遇见祢.mp3', 'id': '19', 'dt': 259.23918367346937, 'url': '/mp3/19 主！我要遇见祢.mp3'},
      {'name': '今生跟随主耶稣.mp3', 'id': '20', 'dt': 304.903875, 'url': '/mp3/20 今生跟随主耶稣.mp3'},
      {'name': '充满我.mp3', 'id': '21', 'dt': 295.0789375, 'url': '/mp3/21 充满我.mp3'},
    ],
    "trackIds": [
      {'id': '01'},
      {'id': '02'},
      {'id': '03'},
      {'id': '04'},
      {'id': '05'},
      {'id': '06'},
      {'id': '07'},
      {'id': '08'},
      {'id': '09'},
      {'id': '10'},
      {'id': '11'},
      {'id': '12'},
      {'id': '13'},
      {'id': '14'},
      {'id': '15'},
      {'id': '16'},
      {'id': '17'},
      {'id': '18'},
      {'id': '19'},
      {'id': '20'},
      {'id': '21'}
    ]
  },
}

export default {
  "get|/playlist/detail": option => {
    return musicDetail
  }
}
